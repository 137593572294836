import { FullHeightBox } from '@fingo/lib/components/boxes';
import { InvoiceType } from '@fingo/lib/propTypes';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import React from 'react';
import AlertConfigList from '../../lists/AlertConfigList';
import PredictiveModelsList from '../../lists/PredictiveModelsList';
import useRiskModelComponent from './useRiskModelsComponents';

const AutomaticEvaluationContent = ({ invoice }) => {
  const selectedComponent = useRiskModelComponent(invoice);

  return (
    <FullHeightBox display="flex">
      <Drawer
        variant="permanent"
        open
        sx={(fingoTheme) => ({
          '& .MuiDrawer-paper': {
            position: 'inherit',
            bgcolor: 'gray.A100',
            border: 0,
          },
          [fingoTheme.breakpoints.down('md')]: {
            display: 'none',
            width: 0,
          },
        })}
        anchor="left"
      >
        <List
          sx={{ width: '100%', maxWidth: 360 }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <PredictiveModelsList invoice={invoice} />
          <AlertConfigList invoice={invoice} />
        </List>
      </Drawer>
      <FullHeightBox flex={1} p={4} bgcolor="common.white">
        {selectedComponent}
      </FullHeightBox>
    </FullHeightBox>
  );
};

AutomaticEvaluationContent.propTypes = {
  invoice: InvoiceType.isRequired,
};

export default AutomaticEvaluationContent;
