import React from 'react';
import dayjs from '@fingo/lib/config/dayjs';
import {
  DateTimeCell,
  TextCell,
  ActionDrawerCell,
  CompanyProfileCell,
} from '@fingo/lib/components/cells';
import {
  AvatarCell,
  TotalAmountCell,
  AvatarExecutiveAssignedCell,
} from '@fingo/lib/components/dataGridCells';
import ClientDocumentsMenu from '@fingo/lib/components/inputs/ClientDocumentsMenu';
import DrawerHeader from '@fingo/lib/components/drawer/DrawerHeader';
import CollectionActions from '@fingo/lib/components/drawer/CollectionActions';
import { formatRut, addMoneyWithCurrency } from '@fingo/lib/helpers';
import Stack from '@mui/system/Stack';
import RiskEvaluationHistoricMetricsDialog from '../dialogs/RiskEvaluationHistoricMetricsDialog';
import TransferCompanyRequirements from '../../../../treasury/components/transfers/TransferCompanyRequirements';
import SLAComponent from '../SLAComponent';

const requestDate = (querysetInEvaluation, filter, asc) => {
  if (querysetInEvaluation.length === 0) return null;
  const firstValue = {
    orderingInEvaluation: querysetInEvaluation[0].evaluationDatetime,
    invoiceInEvaluation:
      querysetInEvaluation[0].preoffer?.preofferevaluationrequest?.createdAt,
  }[filter];
  return querysetInEvaluation.reduce((previousValue, currentValue) => {
    const createdAt = {
      orderingInEvaluation: currentValue.evaluationDatetime,
      invoiceInEvaluation:
        currentValue.preoffer?.preofferevaluationrequest?.createdAt,
    }[filter];
    if (asc) return previousValue < createdAt ? previousValue : createdAt;
    return previousValue > createdAt ? previousValue : createdAt;
  }, firstValue);
};

const retrieveEarliestSLAEvaluation = (masterEntity, filter) => {
  const filterMapper = {
    invoiceInEvaluation: (doc) => doc?.preoffer?.preofferevaluationrequest?.slaResolution ?? null,
    orderingInEvaluation: (doc) => doc?.orderingsimulation?.slaResolution ?? null,
  };
  const slaRequests = masterEntity.company[filter]
    .map(filterMapper[filter])
    .filter((slaResolution) => slaResolution !== null);
  if (!slaRequests.length) return null;
  // return slaResolution with smallest estimatedResolutionDatetime
  // eslint-disable-next-line max-len
  return slaRequests.reduce((minSlaResolution, slaResolution) => (dayjs(slaResolution.estimatedResolutionDatetime)
    < dayjs(minSlaResolution.estimatedResolutionDatetime)
    ? slaResolution
    : minSlaResolution));
};

const debtorsQuantity = (querysetInEvaluation, filter) => Array.from(
  new Set(
    querysetInEvaluation.map(
      (item) => ({
        invoiceInEvaluation: item.receiver?.rut,
        orderingInEvaluation: item.purchaser?.rut,
        invoiceInPendingDocuments: item.receiver?.rut,
      }[filter]),
    ),
  ),
).length;

// eslint-disable-next-line max-len
const totalAmountByCurrency = (querysetInEvaluation, filter) => querysetInEvaluation.reduce((acc, currentValue) => {
  const value = {
    invoiceInEvaluation: currentValue.amountWithIva,
    orderingInEvaluation: currentValue.totalAmount,
    invoiceInPendingDocuments: currentValue.amountWithIva,
  }[filter];

  const currencyCode = value.currency.code;
  acc[currencyCode] = acc[currencyCode]
    ? addMoneyWithCurrency(acc[currencyCode], value)
    : value;

  return acc;
}, {});

const retrieveAssignedEvaluator = (querysetInEvaluation, filter) => ({
  orderingInEvaluation: querysetInEvaluation[0].assignedEvaluator,
  invoiceInEvaluation:
      querysetInEvaluation[0].preoffer?.preofferevaluationrequest
        ?.assignedEvaluator,
  invoiceInPendingDocuments:
      querysetInEvaluation[0].preoffer?.preofferevaluationrequest
        ?.assignedEvaluator,
}[filter]);

const riskEvaluationsColumns = (filter) => [
  {
    field: 'name',
    type: 'string',
    headerName: 'Empresa',
    renderCell: ({ row }) => (
      <CompanyProfileCell masterEntity={row} showRelatedDocuments={false} />
    ),
    sortable: true,
    flex: 2,
  },
  {
    field: 'riskMetabase',
    type: 'string',
    headerName: 'Analítica',
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <RiskEvaluationHistoricMetricsDialog masterEntityId={row.id} />
    ),
    sortable: true,
    flex: 2,
  },
  {
    field: 'company_ExecutiveAssigned__firstName',
    headerName: 'Ejecutivo',
    filterable: true,
    sortable: true,
    maxWidth: 90,
    flex: 1,
    valueGetter: (params) => params.row.company?.executiveAssigned?.firstName,
    renderCell: (params) => (
      <AvatarExecutiveAssignedCell
        companyId={params.row.id}
        user={params.row.company?.executiveAssigned}
        assignationType="executive"
      />
    ),
  },
  {
    field: 'evaluationRequest_AssignedEvaluator_FirstName',
    headerName: 'Evaluador Asignado',
    align: 'center',
    headerAlign: 'center',
    maxWidth: 90,
    flex: 1,
    valueGetter: ({ row }) => row.company.executiveAssigned?.firstName,
    renderCell: ({ row }) => (
      <AvatarCell
        user={retrieveAssignedEvaluator(row.company[filter], filter)}
      />
    ),
  },
  {
    field: 'sla',
    type: 'date',
    headerName: 'SLA',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <SLAComponent row={retrieveEarliestSLAEvaluation(row, filter)} />
    ),
    flex: 1.3,
  },
  {
    field: 'evaluationRequest_FirstCreated',
    type: 'date',
    headerName: 'Fecha primera solicitud',
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <DateTimeCell
        fullDate={dayjs(requestDate(row.company[filter], filter, true))}
      />
    ),
    flex: 1,
  },
  {
    field: 'evaluationRequest_LastCreated',
    type: 'date',
    headerName: 'Fecha última solicitud',
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <DateTimeCell
        fullDate={dayjs(requestDate(row.company[filter], filter, false))}
      />
    ),
    flex: 1,
  },
  {
    field: 'numberEvaluations',
    type: 'string',
    headerName: ['invoiceInEvaluation', 'invoiceInPendingDocuments'].includes(
      filter,
    )
      ? 'Cantidad Facturas'
      : 'Cantidad OCs',
    sortable: true,
    headerAlign: 'center',
    filterable: false,
    renderCell: ({ row }) => (
      <Stack alignItems="center" justifyContent="center" alignContent="center">
        <TextCell text={`${row.company[filter].length}`} />
      </Stack>
    ),
    flex: 0.3,
  },
  {
    field: 'numberDebtors',
    type: 'string',
    headerName: 'Cantidad Deudores',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <Stack alignItems="center" justifyContent="center" alignContent="center">
        <TextCell text={`${debtorsQuantity(row.company[filter], filter)}`} />
      </Stack>
    ),
    flex: 0.3,
  },
  {
    field: 'companyInvoiceInEvaluationAmountSum',
    type: 'string',
    headerName: 'Monto Total',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    renderCell: ({ row }) => {
      const totalAmounts = totalAmountByCurrency(row.company[filter], filter);
      const moneyFields = Object.keys(totalAmounts).map((currencyCode) => ({
        amount: totalAmounts[currencyCode].amount,
        currency: { code: currencyCode },
      }));

      return (
        <Stack
          alignItems="center"
          justifyContent="center"
          alignContent="center"
        >
          <TotalAmountCell moneyFields={moneyFields} />
        </Stack>
      );
    },
    flex: 0.5,
  },
  {
    field: 'clientDocuments',
    type: 'string',
    headerName: 'Documentos',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <ClientDocumentsMenu
        companyId={row?.id}
        documents={row.company.documents || []}
      />
    ),
    flex: 0.5,
  },
  {
    field: 'moreDetails',
    headerName: 'Más Detalles',
    type: 'string',
    sortable: false,
    filterable: false,
    maxWidth: 90,
    flex: 1,
    renderCell: ({ row }) => (
      <ActionDrawerCell
        title={row.name}
        subtitle={formatRut(row.rut)}
        actions={[]}
        headerComponent={DrawerHeader}
        contentComponent={CollectionActions}
      />
    ),
  },
  {
    field: 'companyRequirements',
    headerName: 'Requerimientos',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <TransferCompanyRequirements
        masterEntity={row}
        filterRestriction="Risk"
      />
    ),
  },
];

export default riskEvaluationsColumns;
