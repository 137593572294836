import React, { useMemo } from 'react';
import dayjs from '@fingo/lib/config/dayjs';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { MoneyType } from '@fingo/lib/propTypes';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import {
  formatMoney,
  formatDayMonthYearSlash,
  formatDateTime,
} from '@fingo/lib/helpers';
import GreyCard from '@fingo/lib/components/cards/GreyCard';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import CreditLineColumns from '../../../constants/credit-line-columns';
import { INDICATORS } from '../../credit-line-evaluation-requests/CreditLineSuggestionInnerContent';
import { MASTER_ENTITY_CREDIT_LINES } from '../../../graphql/credit-lines/queries';

const CreditLineEvaluation = ({
  masterEntityId,
  masterEntityName,
  invoiceAmount,
}) => {
  const currency = invoiceAmount.currency.code;
  const { data, loading } = useQuery(MASTER_ENTITY_CREDIT_LINES, {
    variables: { masterEntityId, currency },
  });
  const creditLine = data?.getMasterEntity?.creditLine;
  const creditLineEvaluationRequest = creditLine?.creditLineEvaluationRequestsLast;
  const creditLineIndicators = creditLineEvaluationRequest?.creditlineevaluationvariablesSet[0]
    ?.creditlineevaluationindicatorsSet[0];
  const movements = creditLine?.movements?.edges.map((line) => line.node) ?? [];
  const limitChanges = creditLine?.limitChanges ?? [];
  const lastLimit = creditLine?.currentLimit;
  const cessionsHistory = useMemo(
    () => data?.getMasterEntity?.receiverCessionsHistory
      || data?.getMasterEntity?.company?.cessionsHistory,
    [data],
  );
  const lastMomement = creditLine?.lastMovement;

  const lastUpdateLimit = useMemo(() => {
    if (!lastLimit) return '';
    return formatDayMonthYearSlash(dayjs(lastLimit.createdAt));
  }, [lastLimit]);

  const lastUpdateMovement = useMemo(() => {
    if (!lastMomement) return '';
    return formatDayMonthYearSlash(dayjs(lastMomement.createdAt));
  }, [lastLimit]);

  const limitAmount = useMemo(() => {
    if (!lastLimit) return 0;
    return lastLimit.newLimit.amount;
  }, [lastLimit]);

  const creditUsed = useMemo(() => {
    if (!lastMomement) return 0;
    return lastMomement.totalCreditUsed.amount;
  });

  const evaluatorUser = useMemo(() => {
    if (!creditLineEvaluationRequest) return 'Sin evaluación de linea';
    const { user } = creditLineEvaluationRequest;
    if (!user) return 'Motor de Riesgo';
    return `${user.firstName} ${user.lastName}`;
  }, [creditLineEvaluationRequest]);

  const creditLeft = limitAmount - creditUsed;

  const primaryContent = (content) => (
    <Typography variant="h4" color="primary">
      {content}
    </Typography>
  );

  const resolutionContribution = useMemo(() => {
    if (!creditLineIndicators) return <></>;
    return (
      <List
        dense
        sx={{ listStyleType: 'disc', maxWidth: 450, ml: 'auto', mr: 'auto' }}
      >
        {INDICATORS.map(({ variableName, formatValue, name }) => (
          <ListItem
            key={variableName}
            sx={{
              display: 'list-item',
            }}
            secondaryAction={(
              <ListItemSecondaryAction>
                <Typography variant="h6">
                  {formatValue(creditLineIndicators[variableName])}
                </Typography>
              </ListItemSecondaryAction>
            )}
          >
            <ListItemText
              primaryTypographyProps={{
                variant: 'body1',
              }}
            >
              {name}:
            </ListItemText>
          </ListItem>
        ))}
      </List>
    );
  }, [creditLineIndicators]);

  const resolutionSubheader = useMemo(() => {
    if (!creditLineIndicators) return 'La empresa no tiene calculo asignado de línea de crédito';
    return `Obtenido el ${formatDateTime(
      dayjs(creditLineIndicators.createdAt),
    )}`;
  }, [creditLineIndicators]);

  const resolution = useMemo(
    () => (
      <Typography variant="h5">
        La empresa
        <Typography
          component="span"
          color={creditLeft < invoiceAmount.amount ? 'primary' : '#4caf50'}
          variant="h5"
        >
          {' '}
          {creditLeft < invoiceAmount.amount ? 'NO' : 'SI'} cuenta{' '}
        </Typography>
        con línea suficiente para cubrir la exposición de la factura
      </Typography>
    ),
    [creditLeft],
  );
  if (loading) return <LinearProgress />;
  if (!creditLine) {
    return (
      <Typography variant="h4">
        La empresa no cuenta con linea de crédito
      </Typography>
    );
  }
  return (
    <Grid p={4} container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">Línea de {masterEntityName}</Typography>
      </Grid>
      <Grid container item columnSpacing={2}>
        <Grid item xs={3}>
          <GreyCard
            title="Línea total"
            subheader={`Actualizada el ${lastUpdateLimit}`}
            content={primaryContent(`$${formatMoney(limitAmount)}`)}
          />
        </Grid>
        <Grid item xs={3}>
          <GreyCard
            title="Línea usada"
            subheader={`Utilizada el ${lastUpdateMovement}`}
            content={primaryContent(`$${formatMoney(creditUsed)}`)}
          />
        </Grid>
        <Grid item xs={3}>
          <GreyCard
            title="Línea disponible"
            content={primaryContent(`$${formatMoney(creditLeft)}`)}
          />
        </Grid>
        <Grid item xs={3}>
          <GreyCard
            title="Monto Factura"
            content={primaryContent(invoiceAmount.chileFormat)}
          />
        </Grid>
      </Grid>
      <Grid container item columnSpacing={2}>
        <Grid item xs={9}>
          <GreyCard title="Resolución" content={resolution} />
        </Grid>
        <Grid item xs={3}>
          <GreyCard
            title="Solicitante última evaluación"
            content={primaryContent(evaluatorUser)}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <GreyCard
          title="Indicadores Cálculo Línea de Crédito"
          subheader={resolutionSubheader}
          content={resolutionContribution}
        />
      </Grid>
      <Grid container item columnSpacing={2}>
        <Grid item xs={6}>
          <GreyCard
            title="Exposición Actual"
            subheader={`${cessionsHistory?.cessionsNumber ?? 0} cesiones`}
            content={primaryContent(
              `$${formatMoney(cessionsHistory?.cessionsMoneyAmount ?? 0)}`,
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <GreyCard
            title="Exposición Histórica"
            subheader={`${
              cessionsHistory?.historicCessionsNumber ?? 0
            } cesiones`}
            content={primaryContent(
              `$${formatMoney(
                cessionsHistory?.historicCessionsMoneyAmount ?? 0,
              )}`,
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <GreyCard
          title="Histórico Líneas Asignadas"
          content={(
            <FingoDataGrid
              rows={limitChanges}
              columns={CreditLineColumns}
              serverFilters={false}
              sx={{ minHeight: 250 }}
              hideFooter
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <GreyCard
          title="Histórico Movimiento Lineas"
          content={(
            <FingoDataGrid
              rows={movements}
              columns={CreditLineColumns}
              serverFilters={false}
              sx={{ minHeight: 250 }}
              hideFooter
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

CreditLineEvaluation.propTypes = {
  masterEntityId: PropTypes.string.isRequired,
  masterEntityName: PropTypes.string.isRequired,
  invoiceAmount: MoneyType.isRequired,
};

export default CreditLineEvaluation;
